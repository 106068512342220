import { useFormikContext } from 'formik';
import { ReactElement } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { selectLEAManagementDataEntryMode } from '../../../redux/slices/leaManagementSlice';
import { DataEntryMode } from '../../../types/DataEntryMode';
import { LEAManagementModel } from '../../../types/LEAManagementModel';
import { PanelType } from '../../../types/PanelType';
import { LEAManagementValidationEditSchema } from '../../../types/Validation/LEAManagementValidationEditSchema';
import { LEAManagementValidationSchema } from '../../../types/Validation/LEAManagementValidationSchema';
import {
  isDistrictAdmin,
  isStateAdmin,
} from '../../../utilities/userUtilities';
import FormTextbox from '../../FormInputs/FormTextbox/FormTextbox';
import Panel from '../../Panel/Panel';

const LEAManagementSecondaryInformationPanel = (): ReactElement => {
  const dataEntryMode: DataEntryMode = useAppSelector(
    selectLEAManagementDataEntryMode
  );
  useFormikContext<LEAManagementModel>();
  return (
    <Panel panelType={PanelType.INFO} heading="MSIS Secondary Information">
      <div className="field-row">
        <FormTextbox
          displayName="First Name"
          field="secondaryFirstName"
          validationSchema={
            dataEntryMode === 'new'
              ? LEAManagementValidationSchema
              : LEAManagementValidationEditSchema
          }
          disabled={!isStateAdmin() && !isDistrictAdmin()}
        />
        <FormTextbox
          displayName="Last Name"
          field="secondaryLastName"
          validationSchema={
            dataEntryMode === 'new'
              ? LEAManagementValidationSchema
              : LEAManagementValidationEditSchema
          }
          disabled={!isStateAdmin() && !isDistrictAdmin()}
        />
      </div>

      <div className="field-row">
        <FormTextbox
          displayName="Email Address"
          field="secondaryEmailAddress"
          validationSchema={
            dataEntryMode === 'new'
              ? LEAManagementValidationSchema
              : LEAManagementValidationEditSchema
          }
          disabled={!isStateAdmin() && !isDistrictAdmin()}
        />
        <FormTextbox
          displayName="Telephone Number"
          field="secondaryTelephoneNumber"
          validationSchema={
            dataEntryMode === 'new'
              ? LEAManagementValidationSchema
              : LEAManagementValidationEditSchema
          }
          disabled={!isStateAdmin() && !isDistrictAdmin()}
        />
      </div>
    </Panel>
  );
};
export default LEAManagementSecondaryInformationPanel;
