import { DistrictModel, SchoolModel } from '../types/DistrictModel';

export const convertAllWordsToBeginWithCapitals = (text: string): string => {
  if (!text) return '';

  const words = text.split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substring(1);
  }

  if (words.length === 1) {
    return words[0];
  } else {
    return words.join(' ');
  }
};

export const prependLastword = (
  text: string,
  delimeter = ' ',
  textAfterLastword?: string
): string => {
  if (!text) return '';

  const segments = text.split(delimeter);

  if (segments.length < 2) return segments[0];

  const lastSegment = segments.pop();
  return (
    lastSegment + (textAfterLastword ?? delimeter) + segments.join(delimeter)
  );
};

export const capitalizeFirstLetterOnly = (text: string): string => {
  if (!text) return '';

  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const last4Digits = (input: string): string => {
  if (input && input.length >= 4) {
    const trimedValue = input.trimEnd();
    return trimedValue.substring(trimedValue.length - 4);
  }

  return input;
};

export const formatDistrictForDisplay = (district?: DistrictModel): string => {
  if (!district) {
    return '';
  }

  if (!district.name || !district.districtId) {
    return '';
  }

  return `${district.name} (${district.districtId})`;
};

export const formatSchoolForDisplay = (school?: SchoolModel): string => {
  if (!school) {
    return '';
  }

  if (!school.name || !school.schoolId) {
    return '';
  }

  return `${school.name} (${school.schoolId})`;
};
