/* eslint-disable max-len */
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ReactElement } from 'react';
import { CertificationDistrictItem } from '../../../types/CertificationDetailModel';
import { PillType } from '../../../types/PillType';
import { DistrictCertificationGridPropTypes } from '../../../types/propTypes/DistrictCertificationGridPropTypes';
import { formatToDateString } from '../../../utilities/dateUtilities';
import Pill from '../../Pill/Pill';
import StyledGrid from '../../StyledGrid/StyledGrid';

const DistrictCertificationGrid = ({
  items,
}: DistrictCertificationGridPropTypes): ReactElement => {
  const handleRowId = (row: CertificationDistrictItem): string => {
    return row.leaName;
  };

  const handleDateFormat = (value: string): string => {
    /* istanbul ignore next */
    if (!value) {
      return value;
    }
    return formatToDateString(value);
  };

  const columns: GridColDef<CertificationDistrictItem>[] = [
    {
      field: 'leaNumber',
      headerName: 'LEA Number',
      flex: 0.3,
      minWidth: 100,
    },
    {
      field: 'leaName',
      headerName: 'LEA Name',
      flex: 1,
    },
    {
      field: 'certificationDate',
      headerName: 'Certification Date',
      flex: 1,
      valueGetter: handleDateFormat,
    },
    {
      field: 'certifiedBy',
      headerName: 'Certified By',
      flex: 0.5,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<CertificationDistrictItem>) => {
        return params.value ? (
          <Pill
            status={params.value}
            value={params.value}
            pillType={PillType.CERTIFICATION_STATUS}
          />
        ) : (
          <></>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
    },
  ];

  return (
    <div>
      <StyledGrid<CertificationDistrictItem>
        columns={columns}
        rows={items}
        getRowId={handleRowId}
        hideFooter
      ></StyledGrid>
    </div>
  );
};

export default DistrictCertificationGrid;
