/* eslint-disable max-len */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import {
  getUserDetailsViewModel,
  getUserEditViewModel,
  getUserList,
} from '../../services/user.service';
import { UserDetailsViewModel } from '../../types/UserDetailsViewModel';
import { UserEditViewModel } from '../../types/UserEditViewModel';
import {
  UserEditingFilterPayload,
  UserViewingFilterPayload,
} from '../../types/UserFilterPayload';
import { UserListModel } from '../../types/UserListModel';

export const fetchUserList = createAsyncThunk(
  'user/fetchUserList',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<UserListModel>(
      thunkAPI,
      getUserList({ signal: thunkAPI.signal })
    );
  }
);

export const fetchUserDetailsViewModelById = createAsyncThunk(
  'user/fetchUserDetailsViewModelById',
  async (userId: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<UserDetailsViewModel>(
      thunkAPI,
      getUserDetailsViewModel({ userId }, { signal: thunkAPI.signal })
    );
  }
);

export const fetchUserDetailsViewModelByIdAndDistrictId = createAsyncThunk(
  'user/fetchUserDetailsViewModelByIdAndDistrictId',
  async (payload: UserViewingFilterPayload, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<UserDetailsViewModel>(
      thunkAPI,
      getUserDetailsViewModel(payload, { signal: thunkAPI.signal })
    );
  }
);

export const fetchUserEditViewModelById = createAsyncThunk(
  'user/fetchUserEditViewModelById',
  async (userId: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<UserEditViewModel>(
      thunkAPI,
      getUserEditViewModel({ userId }, { signal: thunkAPI.signal })
    );
  }
);

export const fetchUserEditViewModelByIdAndDistrictId = createAsyncThunk(
  'user/fetchUserEditViewModelByIdAndDistrictId',
  async (payload: UserEditingFilterPayload, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<UserEditViewModel>(
      thunkAPI,
      getUserEditViewModel(payload, { signal: thunkAPI.signal })
    );
  }
);
