import { ReactElement } from 'react';
import { PillType } from '../../types/PillType';
import { PillPropTypes } from '../../types/propTypes/PillPropTypes';
import './Pill.css';

const Pill = ({ status, value, pillType }: PillPropTypes): ReactElement => {
  let pillClass = '';

  switch (pillType) {
    case PillType.ENROLLMENT_STATUS:
      pillClass = 'enrollment-status';
      break;

    case PillType.FILE_STATUS:
      pillClass = 'file-status';
      break;

    case PillType.MSIS_REQUEST_STATUS:
      pillClass = 'msis-request-status';
      break;

    case PillType.USER_STATUS:
      pillClass = 'user-status';
      break;

    case PillType.CERTIFICATION_STATUS:
      pillClass = 'certification-status';
      break;
  }

  return (
    <span
      data-status={status}
      className={`pill ${pillClass} no-wrap-text`}
      data-testid={`pill-${pillClass}`}
      aria-label={value}
    >
      {value}
    </span>
  );
};

export default Pill;
