/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectCertificationReportErrors,
  selectCollectionDetail,
  selectDetailLoading,
  setLookupError,
} from '../../../redux/slices/certificationSlice';
import { CertificationDetailModel } from '../../../types/CertificationDetailModel';
import { MSDE_APP_HELP_EMAIL } from '../../../types/Constants';
import { PanelType } from '../../../types/PanelType';
import { ReportEmbedData } from '../../../types/ReportEmbedData';
import { formatToDateString } from '../../../utilities/dateUtilities';
import { resolveReports } from '../../../utilities/reportUtilities';
import ErrorDetails from '../../ErrorDetails/ErrorDetails';
import Loader from '../../Loader/Loader';
import Panel from '../../Panel/Panel';
import ReportNavigationButton from '../../ReportNavigationButton/ReportNavigationButton';
import DistrictCertificationGrid from '../DistrictCertificationGrid/DistrictCertificationGrid';
import SchoolCertificationGrid from '../SchoolCertificationGrid/SchoolCertificationGrid';
import './CertificationCollectionDetail.css';

const CertificationCollectionDetail = (): ReactElement => {
  const dispatch = useAppDispatch();
  const isDetailLoading = useAppSelector(selectDetailLoading);
  const collectionErrors = useAppSelector(selectCertificationReportErrors);
  const detail: CertificationDetailModel = useAppSelector(
    selectCollectionDetail
  );
  const [reports, setReports] = useState<ReportEmbedData[]>([]);

  useEffect(() => {
    const resolvedList = resolveReports(detail.reportReferences);

    if (resolvedList.errors.length > 0) {
      dispatch(setLookupError(resolvedList.errors));
    } else {
      setReports(resolvedList.reports);
    }
  }, [dispatch, detail.reportReferences]);

  return (
    <div className="certification-detail">
      {isDetailLoading ? (
        <div className="loading-container">
          <Loader
            dataTestId={'loading-detail'}
            message={'Loading Collection Data Points...'}
          />
        </div>
      ) : collectionErrors ? (
        <ErrorDetails
          title={'Certification Report(s) Not Found'}
          subtitle={collectionErrors}
          instructionsToFix={
            <div>
              Please reach out to the Help Desk at{' '}
              <a href={`mailto:${MSDE_APP_HELP_EMAIL}`}>
                {MSDE_APP_HELP_EMAIL}
              </a>{' '}
              and include the above report details.
            </div>
          }
        />
      ) : (
        <div className="detail-container">
          <div
            className="data-collection--heading data-section"
            data-testid="collection-heading"
          >
            {detail.headingText}
          </div>
          <div className="data-collection--dates data-section">
            <div
              className="collection--label date-start-date"
              data-testid="collection-open-date"
            >
              Certification Open Date:{' '}
              {formatToDateString(detail.certificationOpenDate)}
            </div>
            <div
              className="collection--label"
              data-testid="collection-close-date"
            >
              Certification Close Date:{' '}
              {formatToDateString(detail.certificationCloseDate)}
            </div>
          </div>
          <Panel
            heading="Monthly Certification Reports"
            panelType={PanelType.INFO}
          >
            {reports &&
              reports.map((report) => (
                <ReportNavigationButton report={report} key={report.reportId} />
              ))}
          </Panel>
          {detail.schoolCertifications.length > 0 && (
            <div
              className="data-collection--list data-section"
              data-testid="certification-school-list"
            >
              <label className="collection--label">School Certification</label>
              <SchoolCertificationGrid items={detail.schoolCertifications} />
            </div>
          )}
          {detail.districtCertifications.length > 0 && (
            <div
              className="data-collection--list"
              data-testid="certification-district-list"
            >
              <label className="collection--label">LEA Certification</label>
              <DistrictCertificationGrid
                items={detail.districtCertifications}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CertificationCollectionDetail;
