/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { ReactElement } from 'react';
import FormDateSelector from '../../components/FormInputs/FormDateSelector/FormDateSelector';
import FormDropdown from '../../components/FormInputs/FormDropdown/FormDropdown';
import FormTextbox from '../../components/FormInputs/FormTextbox/FormTextbox';
import ManualInputPage from '../../components/ManualInputPage/ManualInputPage';
import Panel from '../../components/Panel/Panel';
import { useAppSelector } from '../../redux/hooks';
import {
  selectMsisIdQueryFormViewModel,
  selectStudentLoading,
} from '../../redux/slices/studentSlice';
import { fetchMSISIdQueryFormViewModel } from '../../redux/thunks/studentThunks';
import { getCurrentUserAccess } from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { createMsisIdRequest } from '../../services/student.service';
import { GLOBAL_DEBUG_MODE } from '../../testing/debugFunctions';
import { MsisIdQueryFormViewModel } from '../../types/MSISIdQueryFormViewModel';
import { PanelType } from '../../types/PanelType';
import { FormDropdownChangeEventConfig } from '../../types/propTypes/FormDropdownPropTypes';
import { ManualInputCrudAction } from '../../types/propTypes/ManualInputPagePropTypes';
import { MSISRequestValidationSchema } from '../../types/Validation/MSISRequestValidationSchema';
import { formatDistrictForDisplay } from '../../utilities/textUtilities';
import {
  getSchoolNameFromOptions,
  isDistrictNonAdmin,
} from '../../utilities/userUtilities';
import './MSISRequestNew.css';

const MSISRequestNew = (): ReactElement => {
  const formViewModel: MsisIdQueryFormViewModel = useAppSelector(
    selectMsisIdQueryFormViewModel
  );
  const isLoading = useAppSelector(selectStudentLoading);
  const user = getCurrentUserAccess();
  const currentDistrict = user?.currentDistrict;
  const getIntitalFormValue = (): Object => ({
    localStudentIdentifier: '',
    requestingDistrictId: currentDistrict?.districtId || '',
    requestingDistrictName: currentDistrict?.name || '',
    schoolID: '',
    schoolName: '',
    msisId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    ssn: '',
    addressStreetNumberAndName: '',
    addressApartmentRoomOrSuiteNumber: '',
    addressCity: '',
    stateAbbreviation: '',
    addressPostalCode: '',
    parentGuardianFirstName: '',
    parentGuardianLastOrSurname: '',
    isSchoolRequired: isDistrictNonAdmin(),
  });

  const districtOptions = currentDistrict
    ? [
        {
          text: formatDistrictForDisplay(currentDistrict),
          value: currentDistrict.districtId,
        },
      ]
    : [];

  const updateSchoolName = async (
    config: FormDropdownChangeEventConfig
  ): Promise<void> => {
    const schoolName = getSchoolNameFromOptions(
      formViewModel.schoolOptions,
      config
    );
    /* istanbul ignore next */
    if (config.setValueHook && schoolName) {
      config.setValueHook('schoolName', schoolName);
    }
  };

  return (
    <ManualInputPage<any, any>
      actions={ManualInputCrudAction.CREATE_ONLY}
      loadingOptions={{
        isLoading: isLoading,
        loadingDataId: 'msis-request-input-loader',
      }}
      classes={{ pageClass: 'msis-request-input' }}
      routes={{
        backBarRoute: appRoutePaths.MSISRequest,
        cancelRoute: appRoutePaths.MSISRequest,
        createSuccessRoute:
          /* istanbul ignore next */
          () => appRoutePaths.MSISRequest,
      }}
      dataFunctions={{
        loadFormDetail: fetchMSISIdQueryFormViewModel,
        onCreate: createMsisIdRequest,
      }}
      formDetails={{
        validationSchema: MSISRequestValidationSchema,
        initialFormValues: getIntitalFormValue(),
        isFormModelEmpty: !formViewModel.stateOptions?.length,
      }}
      config={{
        objectVerbiage: 'MSIS ID request',
        pageHeader: 'MSIS ID Requests',
      }}
      DEV_MODE={GLOBAL_DEBUG_MODE}
    >
      <div data-testid="msis-request-input-child">
        <Panel panelType={PanelType.INFO} heading="District & School Info">
          <div className="field-row field-row-2-equal">
            <FormDropdown
              displayName="District"
              field="requestingDistrictId"
              validationSchema={MSISRequestValidationSchema}
              disabled={true}
              options={districtOptions}
              isMulti={false}
            />
            <FormDropdown
              displayName="School"
              field="schoolID"
              options={formViewModel.schoolOptions}
              validationSchema={MSISRequestValidationSchema}
              classConfig={{
                labelClasses: isDistrictNonAdmin()
                  ? 'forced-required-label'
                  : '',
              }}
              onChangeEvent={updateSchoolName}
              isMulti={false}
            />
          </div>
          <div className="field-row field-row-single-item">
            <FormTextbox
              displayName="Local Student Identifier"
              field="localStudentIdentifier"
              validationSchema={MSISRequestValidationSchema}
            />
          </div>
        </Panel>

        <Panel panelType={PanelType.INFO} heading="Data to Match">
          <div className="field-row field-row-3-equal">
            <FormTextbox
              displayName="First Name"
              field="firstName"
              validationSchema={MSISRequestValidationSchema}
            />
            <FormTextbox
              displayName="Middle Name"
              field="middleName"
              validationSchema={MSISRequestValidationSchema}
            />
            <FormTextbox
              displayName="Last Name"
              field="lastName"
              validationSchema={MSISRequestValidationSchema}
            />
          </div>

          <div className="field-row field-row-2">
            <FormTextbox
              displayName="SSN"
              field="ssn"
              validationSchema={MSISRequestValidationSchema}
            />
            <FormDateSelector
              displayName="Date of Birth"
              field="dateOfBirth"
              validationSchema={MSISRequestValidationSchema}
            />
          </div>
        </Panel>

        <Panel panelType={PanelType.INFO} heading="Additional Context">
          <div className="field-row address-row">
            <FormTextbox
              displayName="Address Street Number and Name"
              field="addressStreetNumberAndName"
              validationSchema={MSISRequestValidationSchema}
            />
          </div>

          <div className="field-row address-row">
            <FormTextbox
              displayName="Apt., Suite, Unit, Building, Floor, etc."
              field="addressApartmentRoomOrSuiteNumber"
              validationSchema={MSISRequestValidationSchema}
            />
          </div>

          <div className="field-row field-row-city-state-zip">
            <FormTextbox
              displayName="City"
              field="addressCity"
              validationSchema={MSISRequestValidationSchema}
            />
            <FormDropdown
              displayName="State"
              field="stateAbbreviation"
              options={formViewModel.stateOptions}
              validationSchema={MSISRequestValidationSchema}
            />
            <FormTextbox
              displayName="Zip Code"
              field="addressPostalCode"
              validationSchema={MSISRequestValidationSchema}
            />
          </div>

          <div className="field-row field-row-2-equal">
            <FormTextbox
              displayName="Custodial Parent/Guardian First Name"
              field="parentGuardianFirstName"
              validationSchema={MSISRequestValidationSchema}
            />
            <FormTextbox
              displayName="Custodial Parent/Guardian Last Name"
              field="parentGuardianLastOrSurname"
              validationSchema={MSISRequestValidationSchema}
            />
          </div>
        </Panel>
      </div>
    </ManualInputPage>
  );
};

export default MSISRequestNew;
