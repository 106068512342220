import { useFormikContext } from 'formik';
import { ReactElement, useState } from 'react';
import FormDropdown from '../../../components/FormInputs/FormDropdown/FormDropdown';
import FormTextbox from '../../../components/FormInputs/FormTextbox/FormTextbox';
import Panel from '../../../components/Panel/Panel';
import { useAppSelector } from '../../../redux/hooks';
import { selectSchoolManagementFormViewModel } from '../../../redux/slices/schoolManagementSlice';
import { PanelType } from '../../../types/PanelType';
import { FormDropdownChangeEventConfig } from '../../../types/propTypes/FormDropdownPropTypes';
import { FormTextboxChangeEventConfig } from '../../../types/propTypes/FormTextboxPropTypes';
import { SchoolManagementFormViewModel } from '../../../types/SchoolManagementFormViewModel';
import { SchoolManagementModel } from '../../../types/SchoolManagementModel';
import { SchoolManagementValidationSchema } from '../../../types/Validation/SchoolManagementValidationSchema';
import {
  getCountyAnsiCodeFromOptions,
  isStateAdmin,
} from '../../../utilities/userUtilities';

const SchoolManagementPhysicalAddressPanel = (): ReactElement => {
  const { values, setFieldValue } = useFormikContext<SchoolManagementModel>();

  const DEFAULT_STATE = 'Mississippi';

  const [
    arePhysicalAddressFieldsRequired,
    setArePhysicalAddressFieldsRequired,
  ] = useState(false);

  const updatePhysicalAddressRequiredFlag = async (
    config: FormTextboxChangeEventConfig
  ): Promise<void> => {
    await setFieldValue(config.field, config.value);
    const updatedValues = {
      ...values,
      [config.field]: config.value,
    };
    const isRequired =
      config.value === '' &&
      updatedValues.physicalAddressStreetNumberAndName === '' &&
      updatedValues.physicalCity === '' &&
      updatedValues.physicalState === '' &&
      updatedValues.physicalZipCode === ''
        ? false
        : true;
    setArePhysicalAddressFieldsRequired(isRequired);
  };

  const getRequiredPhysicalAddressLabel = (): string => {
    return arePhysicalAddressFieldsRequired ? 'forced-required-label' : '';
  };

  const formViewModel: SchoolManagementFormViewModel = useAppSelector(
    selectSchoolManagementFormViewModel
  );
  const [isShowPhysicalCounty, setIsShowPhysicalCounty] = useState(false);

  const updatePhysicalCountyAnsiCode = async (
    config: FormDropdownChangeEventConfig
  ): Promise<void> => {
    await setFieldValue(config.field, config.value);

    const physicalCountyAnsiCode = getCountyAnsiCodeFromOptions(
      formViewModel.countyAnsiCodeOptions,
      config
    );
    /* istanbul ignore next */
    if (config.setValueHook && physicalCountyAnsiCode) {
      config.setValueHook('physcialCountyAnsiCode', physicalCountyAnsiCode);
    }
  };

  const updateShowPhysicalCounty = async (
    config: FormDropdownChangeEventConfig
  ): Promise<void> => {
    const showCounty = config.value === DEFAULT_STATE ? true : false;
    setIsShowPhysicalCounty(showCounty);
    setArePhysicalAddressFieldsRequired(true);
  };

  return (
    <Panel panelType={PanelType.INFO} heading="Physical Address">
      <div className="field-row">
        <FormTextbox
          displayName="Address Street Number and Name"
          field="physicalAddressStreetNumberAndName"
          classConfig={{ labelClasses: getRequiredPhysicalAddressLabel() }}
          validationSchema={SchoolManagementValidationSchema}
          onChangeEvent={updatePhysicalAddressRequiredFlag}
          disabled={!isStateAdmin()}
        />
        <FormTextbox
          displayName="Apt., Suite, Unit, Building, Floor, Etc."
          field="physicalAptSuiteBuildingFloorNumber"
          validationSchema={SchoolManagementValidationSchema}
          disabled={!isStateAdmin()}
        />
      </div>
      <div className="field-row">
        <FormTextbox
          displayName="City"
          field="physicalCity"
          classConfig={{ labelClasses: getRequiredPhysicalAddressLabel() }}
          validationSchema={SchoolManagementValidationSchema}
          onChangeEvent={updatePhysicalAddressRequiredFlag}
          disabled={!isStateAdmin()}
        />
        <FormDropdown
          displayName="State"
          field="physicalState"
          options={formViewModel.stateOptions}
          classConfig={{ labelClasses: getRequiredPhysicalAddressLabel() }}
          validationSchema={SchoolManagementValidationSchema}
          onChangeEvent={updateShowPhysicalCounty}
          disabled={!isStateAdmin()}
        />
        <FormTextbox
          displayName="Zip Code"
          field="physicalZipCode"
          classConfig={{ labelClasses: getRequiredPhysicalAddressLabel() }}
          validationSchema={SchoolManagementValidationSchema}
          onChangeEvent={updatePhysicalAddressRequiredFlag}
          disabled={!isStateAdmin()}
        />
      </div>
      {isShowPhysicalCounty && (
        <div className="field-row">
          <FormDropdown
            displayName="County"
            field="physicalCounty"
            options={formViewModel.countyOptions}
            classConfig={{ labelClasses: getRequiredPhysicalAddressLabel() }}
            validationSchema={SchoolManagementValidationSchema}
            onChangeEvent={updatePhysicalCountyAnsiCode}
            disabled={!isStateAdmin()}
          />
          <FormTextbox
            displayName="County ANSI Code"
            field="physicalCountyAnsiCode"
            disabled={true}
          />
        </div>
      )}
    </Panel>
  );
};

export default SchoolManagementPhysicalAddressPanel;
