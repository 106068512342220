import { LicenseInfo } from '@mui/x-license';
import { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import Authenticate from './pages/Authenticate/Authenticate';
import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import Wireframe from './pages/Wireframe/Wireframe';
import { getDataGridProLicenseKey } from './services/app.service';
import { appRoutePaths } from './services/route.service';

const App = (): ReactElement => {
  LicenseInfo.setLicenseKey(getDataGridProLicenseKey());
  return (
    <Routes>
      <Route path="*" element={<Wireframe />} />
      <Route path={appRoutePaths.Login} element={<Login />} />
      <Route path={appRoutePaths.GetToken} element={<Authenticate />} />
      <Route path={appRoutePaths.Logout} element={<Logout />} />
    </Routes>
  );
};

export default App;
