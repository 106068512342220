import { ReactElement } from 'react';
import './Unauthorized.css';

const Unauthorized = (): ReactElement => {
  return (
    <div className="page--unauthorized">
      <section className="page--unauthorized-section">
        <div className="content">
          <p data-testid="unauthorized-message">
            The permissions you are granted for the LEA you are viewing do not
            allow you to see this page.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Unauthorized;
