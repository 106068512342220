/* eslint-disable max-len */
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ReactElement } from 'react';
import { CertificationSchoolItem } from '../../../types/CertificationDetailModel';
import { PillType } from '../../../types/PillType';
import { SchoolCertificationGridPropTypes } from '../../../types/propTypes/SchoolCertificationGridPropTypes';
import { formatToDateString } from '../../../utilities/dateUtilities';
import Pill from '../../Pill/Pill';
import StyledGrid from '../../StyledGrid/StyledGrid';
import './SchoolCertificationGrid.css';

const SchoolCertificationGrid = ({
  items,
}: SchoolCertificationGridPropTypes): ReactElement => {
  const handleRowId = (row: CertificationSchoolItem): string => {
    return row.schoolNumber;
  };

  const handleDateFormat = (value: string): string => {
    /* istanbul ignore next */
    if (!value) {
      return value;
    }
    return formatToDateString(value);
  };

  const columns: GridColDef<CertificationSchoolItem>[] = [
    {
      field: 'schoolNumber',
      headerName: 'School Number',
      flex: 0.3,
      minWidth: 100,
    },
    {
      field: 'schoolName',
      headerName: 'School Name',
      flex: 1,
    },
    {
      field: 'certificationDate',
      headerName: 'Certification Date',
      flex: 1,
      valueGetter: handleDateFormat,
    },
    {
      field: 'certifiedBy',
      headerName: 'Certified By',
      flex: 0.5,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<CertificationSchoolItem>) => {
        return params.value ? (
          <Pill
            status={params.value}
            value={params.value}
            pillType={PillType.CERTIFICATION_STATUS}
          />
        ) : (
          <></>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      // type: 'actions',
      // getActions: (params) => [
      //   <GridActionsCellItem
      //     icon={<DeleteIcon />}
      //     label="Delete"
      //     onClick={deleteUser(params.id)}
      //   />,
      //   <GridActionsCellItem
      //     icon={<SecurityIcon />}
      //     label="Toggle Admin"
      //     onClick={toggleAdmin(params.id)}
      //     showInMenu
      //   />,
      //   <GridActionsCellItem
      //     icon={<FileCopyIcon />}
      //     label="Duplicate User"
      //     onClick={duplicateUser(params.id)}
      //     showInMenu
      //   />,
      // ],
    },
  ];

  return (
    <div className="school-certification-grid">
      <StyledGrid<CertificationSchoolItem>
        columns={columns}
        rows={items}
        getRowId={handleRowId}
        initialState={{
          sorting: {
            sortModel: [{ field: 'schoolName', sort: 'asc' }],
          },
        }}
        hideFooter
      ></StyledGrid>
    </div>
  );
};

export default SchoolCertificationGrid;
