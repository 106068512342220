/* eslint-disable max-len */
import { get } from 'lodash';
import { DataDomainPermission } from '../types/DataDomainPermission';
import { DataDomainPermissionMetadata } from '../types/DataDomainPermissionMetadata';
import { SchoolModel } from '../types/DistrictModel';
import {
  FormDataDomainPermission,
  UserEditFormModel,
  UserEditModel,
} from '../types/UserEditModel';
import { UserEditViewModel } from '../types/UserEditViewModel';
import { UserType } from '../types/UserType';

export const mapToFormModel = (model: UserEditViewModel): UserEditFormModel => {
  const convertFormSchoolPermissionListToObject = (
    dataDomainPermissionMetadata: DataDomainPermissionMetadata
  ): FormDataDomainPermission => {
    const schools: SchoolModel[] = get(
      model,
      `user.permissionsByDataDomainForEditingDistrict[${dataDomainPermissionMetadata.name}].schools`,
      []
    );
    return {
      schoolIds: schools.map((schl) => schl.schoolId),
      canCertifyDistrict: get(
        model,
        `user.permissionsByDataDomainForEditingDistrict[${dataDomainPermissionMetadata.name}].canCertifyDistrict`,
        false
      ),
      canCertifySchools: get(
        model,
        `user.permissionsByDataDomainForEditingDistrict[${dataDomainPermissionMetadata.name}].canCertifySchools`,
        false
      ),
      msisIdRequestAccess: get(
        model,
        `user.permissionsByDataDomainForEditingDistrict[${dataDomainPermissionMetadata.name}].msisIdRequestAccess`,
        false
      ),
      salaryAccess: get(
        model,
        `user.permissionsByDataDomainForEditingDistrict[${dataDomainPermissionMetadata.name}].salaryAccess`,
        false
      ),
      studentOwnershipAccess: get(
        model,
        `user.permissionsByDataDomainForEditingDistrict[${dataDomainPermissionMetadata.name}].studentOwnershipAccess`,
        false
      ),
    };
  };
  const buildPermissionFormList = (): Record<
    string,
    FormDataDomainPermission
  > => {
    const obj: Record<string, FormDataDomainPermission> = {};
    model.assignableDataDomainPermissions.map((role) => {
      obj[role.name] = convertFormSchoolPermissionListToObject(role);
    });
    return obj;
  };

  const statewideDataDomains =
    model.user.isAdmin && model.user.userType == UserType.State
      ? model.assignableStatewideDataDomains
      : model.user.statewideDataDomainPermissions;

  return {
    statewideDataDomains,
    permissionsByDataDomain: buildPermissionFormList(),
  };
};

export const mapFormToEditModel = (
  model: UserEditFormModel,
  schoolRoles: string[],
  schools: SchoolModel[]
): UserEditModel => {
  const convertSchoolIdListToPermission = (
    dataDomain: string
  ): DataDomainPermission => {
    const schoolIds: string[] = get(
      model,
      `permissionsByDataDomain[${dataDomain}].schoolIds`,
      []
    );
    return {
      schools: schools.filter((schl) => schoolIds.includes(schl.schoolId)),
      canCertifyDistrict: get(
        model,
        `permissionsByDataDomain[${dataDomain}].canCertifyDistrict`,
        false
      ),
      canCertifySchools: get(
        model,
        `permissionsByDataDomain[${dataDomain}].canCertifySchools`,
        false
      ),
      msisIdRequestAccess: get(
        model,
        `permissionsByDataDomain[${dataDomain}].msisIdRequestAccess`,
        false
      ),
      salaryAccess: get(
        model,
        `permissionsByDataDomain[${dataDomain}].salaryAccess`,
        false
      ),
      studentOwnershipAccess: get(
        model,
        `permissionsByDataDomain[${dataDomain}].studentOwnershipAccess`,
        false
      ),
    };
  };

  const buildPermissionsByDataDomain = (): Record<
    string,
    DataDomainPermission
  > => {
    const obj: Record<string, DataDomainPermission> = {};
    schoolRoles.map((role) => {
      if (convertSchoolIdListToPermission(role).schools.length > 0) {
        obj[role] = convertSchoolIdListToPermission(role);
      }
    });
    return obj;
  };
  return {
    statewideDataDomains: model.statewideDataDomains,
    permissionsByDataDomain: buildPermissionsByDataDomain(),
  };
};
