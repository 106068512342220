import { useFormikContext } from 'formik';
import { ReactElement, useEffect, useState } from 'react';
import CustomFormTextbox from '../../../components/FormInputs/CustomFormTextbox/CustomFormTextbox';
import FormCheckboxList from '../../../components/FormInputs/FormCheckboxList/FormCheckboxList';
import FormDateSelector from '../../../components/FormInputs/FormDateSelector/FormDateSelector';
import FormDropdown from '../../../components/FormInputs/FormDropdown/FormDropdown';
import FormRadioList from '../../../components/FormInputs/FormRadioList/FormRadioList';
import FormTextbox from '../../../components/FormInputs/FormTextbox/FormTextbox';
import Panel from '../../../components/Panel/Panel';
import { useAppSelector } from '../../../redux/hooks';
import {
  selectSchoolManagementDataEntryMode,
  selectSchoolManagementFormViewModel,
} from '../../../redux/slices/schoolManagementSlice';
import { getCurrentUserAccess } from '../../../services/base.service';
import { DataEntryMode } from '../../../types/DataEntryMode';
import { PanelType } from '../../../types/PanelType';
import { FormRadioListOrientation } from '../../../types/propTypes/FormRadioListPropTypes';
import { FormTextboxChangeEventConfig } from '../../../types/propTypes/FormTextboxPropTypes';
import { SchoolManagementFormViewModel } from '../../../types/SchoolManagementFormViewModel';
import { SchoolManagementModel } from '../../../types/SchoolManagementModel';
import { SchoolManagementValidationSchema } from '../../../types/Validation/SchoolManagementValidationSchema';
import { isStateAdmin } from '../../../utilities/userUtilities';

const SchoolManagementSchoolInformationPanel = (): ReactElement => {
  const [isCharterSchoolTypeDisabled, setIsCharterSchoolTypeDisabled] =
    useState(false);

  const formViewModel: SchoolManagementFormViewModel = useAppSelector(
    selectSchoolManagementFormViewModel
  );
  useFormikContext<SchoolManagementModel>();

  const [isReportedFederallyDisabled, setIsReportedFederallyDisabled] =
    useState(false);

  const dataEntryMode: DataEntryMode = useAppSelector(
    selectSchoolManagementDataEntryMode
  );
  const updateReportedFederally = async (
    config: FormTextboxChangeEventConfig
  ): Promise<void> => {
    let reportedFederally =
      config.value === '500' || config.value === '200' ? 'No' : 'Yes';
    let reportedFederallyDisabled = reportedFederally === 'No' ? true : false;

    /* istanbul ignore next */
    if (config.setValueHook && reportedFederally) {
      if (dataEntryMode === DataEntryMode.EDIT) {
        reportedFederallyDisabled = false;
      }
      setIsReportedFederallyDisabled(reportedFederallyDisabled);

      if (reportedFederally === 'Yes') {
        reportedFederally = '';
      }
      config.setValueHook('reportedFederally', reportedFederally);
    }
  };

  const getDistrictType = (): string => {
    const user = getCurrentUserAccess();
    const districtType = user?.currentDistrict?.type || '';

    const charterSchoolIndicator =
      districtType === 'Independent Charter District' ? 'Yes' : 'No';
    return `${charterSchoolIndicator}`;
  };

  useEffect(() => {
    let charterSchoolTypeDisabledFlag =
      getDistrictType() === 'No' ? true : false;

    if (dataEntryMode === DataEntryMode.EDIT) {
      charterSchoolTypeDisabledFlag = false;
    }

    setIsCharterSchoolTypeDisabled(charterSchoolTypeDisabledFlag);
  }, [dataEntryMode]);

  return (
    <Panel panelType={PanelType.INFO} heading="School Information">
      <div className="field-row">
        <FormTextbox
          displayName="Name of Institution"
          field="schoolName"
          validationSchema={SchoolManagementValidationSchema}
          disabled={!isStateAdmin()}
        />
        <CustomFormTextbox
          displayName="School Identifier"
          field1="schoolIdentifierLeaPart"
          field2="schoolIdentifierSchoolPart"
          validationSchema={SchoolManagementValidationSchema}
          onChangeEvent={updateReportedFederally}
          disabled={!isStateAdmin()}
        />
        <FormTextbox
          displayName="LEA Identifier"
          field="leaIdentifier"
          disabled={true}
        />
      </div>

      <div className="field-row field-row-grid-2-1-1-1">
        <FormDropdown
          displayName="School Type"
          field="schoolType"
          options={formViewModel.schoolTypeOptions}
          validationSchema={SchoolManagementValidationSchema}
          disabled={!isStateAdmin()}
        />
        <FormRadioList
          displayName="Reported Federally"
          field="reportedFederally"
          options={[
            { text: 'Yes', value: 'Yes' },
            { text: 'No', value: 'No' },
          ]}
          optionOrientation={FormRadioListOrientation.Horizontal}
          validationSchema={SchoolManagementValidationSchema}
          disabled={isReportedFederallyDisabled || !isStateAdmin()}
        />

        <FormTextbox
          displayName="ACT Identifier"
          field="actIdentifier"
          validationSchema={SchoolManagementValidationSchema}
          disabled={!isStateAdmin()}
        />
        <FormTextbox
          displayName="NCES Identifier"
          field="ncesIdentifier"
          validationSchema={SchoolManagementValidationSchema}
          disabled={!isStateAdmin()}
        />
      </div>
      <div className="field-row">
        <FormRadioList
          displayName="Charter School"
          field="charterSchool"
          options={[
            { text: 'Yes', value: 'Yes' },
            { text: 'No', value: 'No' },
          ]}
          optionOrientation={FormRadioListOrientation.Horizontal}
          validationSchema={SchoolManagementValidationSchema}
          disabled={dataEntryMode === DataEntryMode.NEW || !isStateAdmin()}
        />
        <FormDropdown
          displayName="Charter School Type"
          field="charterSchoolType"
          options={formViewModel.charterSchoolTypeOptions}
          validationSchema={SchoolManagementValidationSchema}
          disabled={isCharterSchoolTypeDisabled || !isStateAdmin()}
        />
        <FormDropdown
          displayName="School Operational Status"
          field="schoolOperationalStatus"
          options={formViewModel.schoolOperationalStatusOptions}
          validationSchema={SchoolManagementValidationSchema}
          disabled={!isStateAdmin()}
        />

        <FormDateSelector
          displayName="Operational Status Effective Date"
          field="operationalStatusEffectiveDate"
          validationSchema={SchoolManagementValidationSchema}
          disabled={!isStateAdmin()}
        />
      </div>

      <div className="field-row">
        <FormTextbox
          displayName="Website"
          field="website"
          validationSchema={SchoolManagementValidationSchema}
          disabled={!isStateAdmin()}
        />
        <FormTextbox
          displayName="Telephone Number"
          field="phone"
          validationSchema={SchoolManagementValidationSchema}
          disabled={!isStateAdmin()}
        />
      </div>
      <div className="field-row">
        <FormCheckboxList
          displayName="Grades"
          field="grades"
          options={formViewModel.gradeOptions}
          addAllCheckbox={true}
          classConfig={{ containerClasses: 'grades-container' }}
          validationSchema={SchoolManagementValidationSchema}
          disabled={!isStateAdmin()}
        />
        <FormRadioList
          displayName="Magnet School"
          field="magnetSchool"
          options={[
            { text: 'Yes', value: 'Yes' },
            { text: 'No', value: 'No' },
          ]}
          optionOrientation={FormRadioListOrientation.Horizontal}
          validationSchema={SchoolManagementValidationSchema}
          disabled={!isStateAdmin()}
        />
        <FormRadioList
          displayName="Virtual School"
          field="virtualSchool"
          options={[
            { text: 'Yes', value: 'Yes' },
            { text: 'No', value: 'No' },
          ]}
          optionOrientation={FormRadioListOrientation.Horizontal}
          validationSchema={SchoolManagementValidationSchema}
          disabled={!isStateAdmin()}
        />
      </div>
    </Panel>
  );
};

export default SchoolManagementSchoolInformationPanel;
